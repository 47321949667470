import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  company: Yup.string()
    .min('Company name must be 2 characters minimum')
    .required('Company is required'),
  country: Yup.string()
    .min(2, 'Country must be 2 characters minimum')
    .required('Country is required'),
  phone: Yup.string()
    .min(2, 'Phone must be 2 characters minimum')
    .required('Phone is required'),    
  product: Yup.string()
    .required('Product is required'),
  productversion: Yup.string()
    .min(2, 'Product version must be 2 characters minimum')
    .required('Product version is required'),
  platform: Yup.string()
    .min(2, 'Platform must be 2 characters minimum')
    .required('Platform is required'),
  sysmodel: Yup.string()
    .min(2, 'System model must be 2 characters minimum')
    .required('System model version is required'),
  group: Yup.string()
    .min(2, 'Group must be 2 characters minimum')
    .required('Group is required'), 
  serial: Yup.string()
    .min(2, 'Serial must be 2 characters minimum')
    .required('Serial is required'),
  passwordtype: Yup.string()
    .required('Password type is required'),                      
  subscribe: Yup.bool().oneOf([true], 'Terms and conditions need to be accepted'),
})

const formUrl = 'https://script.google.com/macros/s/AKfycby1ZjnbwMuRo-GoRdjZHsczAIhvdbxWktziihuebR0v_9j6qysus7Wk/exec'

class ContactMain extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true })
    })
  }

  handleChange = value => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol md="6" className="md-0 mb-5">
            <Formik
              initialValues={{
                name: '',
                email: '',
                company: '',
                country: '',         
                product: '',
                automonbatch: '',
                automoncics: '',
                automonloganalyzer: '',
                automonrdo: '',
                automontcpip: '',
                automonvtam: '',
                carts: '',
                cicsfctd: '',
                cicsfileserv: '',
                cicsjuggler: '',
                cicswindows: '',
                comet: '',
                helpwindows: '',
                piecics: '',
                pierex: '',
                pietso: '',
                ssa: '',
                ssr: '',
                supermoncics: '',
                tapesaver: '',
                uccfbatch: '',
                uccfserver: '',
                unicomcentral: '',
                vtamwindows: '',
                zware: '',
                productversion: '',  
                platform: '', 
                cicsversion: '',
                sysmodel: '',
                group: '',
                serial: '',
                passwordtype: '',
                message: '',
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Name: values.name,
                  Email: values.email,
                  Company: values.company,
                  Country: values.country,             
                  Product: values.product,                                                       
                  automonbatch: values.automonbatch,
                  automoncics: values.automoncics,
                  automonloganalyzer: values.automonloganalyzer,
                  automonrdo: values.automonrdo,
                  automontcpip: values.automontcpip,
                  automonvtam: values.automonvtam,
                  carts: values.carts,
                  cicsfctd: values.cicsfctd,
                  cicsfileserv: values.cicsfileserv,
                  cicsjuggler: values.cicsjuggler,
                  cicswindows: values.cicswindows,
                  comet: values.comet,
                  helpwindows: values.helpwindows,
                  piecics: values.piecics,
                  pierex: values.pierex,
                  pietso: values.pietso,
                  ssa: values.ssa,
                  ssr: values.ssr,
                  supermoncics: values.supermoncics,
                  tapesaver: values.tapesaver,
                  uccfbatch: values.uccfbatch,
                  uccfserver: values.uccfserver,
                  unicomcentral: values.unicomcentral,
                  vtamwindows: values.vtamwindows,
                  zware: values.zware,
                  Productversion: values.productversion,
                  Platform: values.platform,
                  Cicsversion: values.cicsversion,
                  Sysmodel: values.sysmodel,
                  Group: values.group,
                  Serial: values.serial,                                                      
                  Passwordtype: values.passwordtype,
                  Message: values.message,
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                      method: 'get',
                      url: `${formUrl}?
                      name=${encodeURIComponent(values.name)}
                      &email=${encodeURIComponent(values.email)}
                      &company=${encodeURIComponent(values.company)}
                      &country=${encodeURIComponent(values.country)}
                      &product=${encodeURIComponent(values.product)}                      
                      &productversion=${encodeURIComponent(values.productversion)}   
                      &platform=${encodeURIComponent(values.platform)}                                          
                      &cicsversion=${encodeURIComponent(values.cicsversion)}
                      &sysmodel=${encodeURIComponent(values.sysmodel)}
                      &group=${encodeURIComponent(values.group)}
                      &serial=${encodeURIComponent(values.serial)}
                      &passwordtype=${encodeURIComponent(values.passwordtype)}                      
                      &message=${encodeURIComponent(values.message)}
                      &subscribe=${encodeURIComponent(values.subscribe)}`
                    })
                      //console.log(response)
                      this.setState({values: '', expired: 'true'})
                      resetForm()
                    } catch (e) {
                      console.log(`Axios request failed: ${e}`)
                    }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form name="contact" method="post" action="" data-netlify="true" data-netlify-honeypot="bot-field" >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="name"> Name <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="name "name="name" aria-label="name" value={values.name} placeholder="Your full name" className={`form-control ${ touched.name && errors.name ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="name" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="email"> Email <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="email" name="email" aria-label="email" value={values.email} placeholder="Your email address" className={`form-control ${ touched.email && errors.email ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="email" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="company"> Company Name <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="company" name="company" aria-label="company" value={values.company} placeholder="Your company name" className={`form-control ${ touched.company && errors.company ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="company" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country"> Country <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="country" name="country" aria-label="country" value={values.country} placeholder="Your country of residence" className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="country" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>                 

                  <hr /> 

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="product"> Product <span style={{ color: 'red' }}>*</span> </label>
                        <Field
                          type="product"
                          name="product"
                          id="product"
                          as="select"
                          value={values.product}
                          placeholder="Please select an option"
                          className={`form-control ${
                            touched.product && errors.product
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option value="" label="Please select a value">
                            {' '}
                          </option>
                          <option value="AUTOMON®/BATCH" label="AUTOMON®/BATCH">
                            AUTOMON®/BATCH
                          </option>
                          <option value="AUTOMON®/CICS" label="AUTOMON®/CICS">
                            AUTOMON®/CICS
                          </option>
                          <option value="AUTOMON®/LogAnalyzer" label="AUTOMON®/LogAnalyzer">
                            AUTOMON®/LogAnalyzer
                          </option>
                          <option value="AUTOMON®/RDO" label="AUTOMON®/RDO">
                            AUTOMON®/RDO
                          </option>
                          <option value="AUTOMON®/TCPIP" label="AUTOMON®/TCPIP">
                            AUTOMON®/TCPIP
                          </option>
                          <option value="AUTOMON®/VTAM" label="AUTOMON®/VTAM">
                            AUTOMON®/VTAM
                          </option>
                          <option value="CARTS" label="CARTS">
                            CARTS
                          </option>
                          <option value="CICS-FCTD" label="CICS-FCTD">
                            CICS-FCTD
                          </option>
                          <option value="CICS-FileServ" label="CICS-FileServ">
                            CICS-FileServ
                          </option>
                          <option value="CICS-Juggler®" label="CICS-Juggler®">
                            CICS-Juggler®
                          </option>
                          <option value="CICS-Windows" label="CICS-Windows">
                            CICS-Windows
                          </option>
                          <option value="Comet" label="Comet">
                            Comet
                          </option>
                          <option value="Help-Windows" label="Help-Windows">
                            Help-Windows
                          </option>
                          <option value="PIE®/CICS" label="PIE®/CICS">
                            PIE®/CICS
                          </option>
                          <option value="PIE®/Rexx" label="PIE®/Rexx">
                            PIE®/Rexx
                          </option>
                          <option value="PIE®/TSO" label="PIE®/TSO">
                            PIE®/TSO
                          </option>
                          <option value="SSA" label="SSA">
                            SSA
                          </option>
                          <option value="SSR" label="SSR">
                            SSR
                          </option>
                          <option value="SUPERMON®/CICS" label="SUPERMON®/CICS">
                            SUPERMON®/CICS
                          </option>
                          <option value="TapeSaver®" label="TapeSaver®">
                            TapeSaver®
                          </option>
                          <option value="UCCF®/Batch" label="UCCF®/Batch">
                            UCCF®/Batch
                          </option>
                          <option value="UCCF®/Batch/Server" label="UCCF®/Batch/Server">
                            UCCF®/Batch/Server
                          </option>
                          <option value="UNICOM®/CENTRAL®" label="UNICOM®/CENTRAL®">
                            UNICOM®/CENTRAL®
                          </option>
                          <option value="VTAM-Windows" label="VTAM-Windows">
                            VTAM-Windows
                          </option>
                          <option value="z/Ware" label="z/Ware">
                            z/Ware
                          </option>
                          <option value="Other" label="Other">
                            Other
                          </option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="product"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>                             

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="productversion"> Product version <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="productversion" name="productversion" aria-label="productversion" value={values.productversion} placeholder="What is your product version?" className={`form-control ${ touched.productversion && errors.productversion ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="productversion" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="platform"> Platform <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="platform" name="platform" aria-label="platform" value={values.platform} placeholder="What is your platform?" className={`form-control ${ touched.platform && errors.platform ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="platform" className="invalid-feedback" />
                      </div>
                    </MDBCol>                    
                  </MDBRow>             

                  <hr />

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="cicsversion"> CICS version (if applicable) </label>
                        <Field type="text" id="cicsversion" name="cicsversion" aria-label="cicsversion" value={values.cicsversion} placeholder="What is your CICS version?" className={`form-control ${ touched.cicsversion && errors.cicsversion ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="cicsversion" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="sysmodel"> System model <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="sysmodel" name="sysmodel" aria-label="sysmodel" value={values.sysmodel} placeholder="What is your system model?" className={`form-control ${ touched.sysmodel && errors.sysmodel ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="sysmodel" className="invalid-feedback" />
                      </div>
                    </MDBCol>                    
                  </MDBRow> 

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="group"> Group <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="group" name="group" aria-label="group" value={values.group} placeholder="What is your group?" className={`form-control ${ touched.group && errors.group ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="group" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                      <label htmlFor="serial"> Serial <span style={{ color: 'red' }}>*</span> </label>
                        <Field type="text" id="serial" name="serial" aria-label="serial" value={values.serial} placeholder="What is your serial?" className={`form-control ${ touched.serial && errors.serial ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="serial" className="invalid-feedback" />
                      </div>
                    </MDBCol>                    
                  </MDBRow>

                  <hr />

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="passwordtype"> Password type <span style={{ color: 'red' }}>*</span> </label>
                        <Field
                          type="passwordtype"
                          name="passwordtype"
                          id="passwordtype"
                          as="select"
                          value={values.passwordtype}
                          placeholder="Please select an option"
                          className={`form-control ${
                            touched.passwordtype && errors.passwordtype
                              ? 'is-invalid'
                              : ''
                          }`}
                        >
                          <option value="" label="Please select a value">
                            {' '}
                          </option>
                          <option value="License key" label="License key">
                            License key
                          </option>
                          <option value="Disaster recovery key" label="Disaster recovery key">
                            Disaster recovery key
                          </option>
                          <option value="New installation key" label="New installation key">
                            New installation key
                          </option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="passwordtype"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow> 

                  <hr />

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="message">Message</label>
                        <Field type="textarea" component="textarea" name="message" aria-label="message" value={values.message} placeholder="Need to leave us a message?" className={`form-control ${ touched.message && errors.message ? 'is-invalid' : '' }`} />
                        <ErrorMessage component="div" name="message" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about UNICOM Systems, Inc.
                        products, services, news and events, please tick the box
                        below. You can unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${
                            touched.subscribe && errors.subscribe
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <label htmlFor="subscribe">
                          Yes, I would like to receive communications from
                          UNICOM Systems, Inc.
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please
                        visit our{' '}
                        <Link to="/privacy-statement/" className="effect">
                          Privacy Statement
                        </Link>
                        .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>

     
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default ContactMain
